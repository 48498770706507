<template>
  <div>
    <a-modal
      :visible="visible"
      title="入库"
      :width="1400"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      okText="入库"
      @cancel="cancel"
      @ok="confirm"
    >
      <a-row style="max-height: 400px; overflow: auto">
        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="dataItems"
            :pagination="false"
            :loading="queryLoading"
          >
            <div slot="stock_in_quantity" slot-scope="value, item, index">
              <div v-if="item.isTotal">{{ value }}</div>
              <a-input-number v-else v-model="item.stock_in_quantity" :min="0" size="small"></a-input-number>
            </div>
            <div slot="warehouse" slot-scope="value, item, index">
              <a-select v-if="!item.isTotal" v-model="item.warehouse" size="small" style="width: 100%">
                <a-select-option v-for="item in item.inventory_items" :key="item.warehouse" :value="item.warehouse">
                  {{ item.warehouse_name }} | 库存: {{ item.total_quantity }}
                </a-select-option>
              </a-select>
            </div>
            <div slot="batch_number" slot-scope="value, item, index">
              <a-input
                v-if="!item.isTotal"
                :disabled="!item.editable_batch_number"
                v-model="item.batch_number"
                size="small"
              ></a-input>
            </div>
            <div slot="production_date" slot-scope="value, item, index">
              <a-date-picker
                v-if="!item.isTotal"
                v-model="item.production_date"
                :disabled="!item.enable_shelf_life"
                valueFormat="YYYY-MM-DD"
                size="small"
                style="width: 100%"
              />
            </div>
            <div slot="action" slot-scope="value, item, index">
              <a-button-group v-if="!item.isTotal" size="small">
                <a-button type="danger" @click="removeProduct(index)">移除</a-button>
              </a-button-group>
            </div>
          </a-table>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { userOption, warehousesOption } from "@/api/option";
import { stockInCreate } from "@/api/warehouse";
import NP from "number-precision";
import moment from "moment";

export default {
  props: ["visible", "items"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      queryLoading: false,
      confirmLoading: false,
      dataItems: [],
      warehouseItems: [],
      handlerItems: [],

      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "入库单号",
          dataIndex: "stock_in_order_number",
          width: 200,
        },
        {
          title: "应入库数量",
          dataIndex: "total_quantity",
          width: 120,
        },
        {
          title: "待入库数量",
          dataIndex: "remain_quantity",
          width: 120,
        },
        {
          title: "入库数量",
          dataIndex: "stock_in_quantity",
          key: "stock_in_quantity",
          width: 120,
          scopedSlots: { customRender: "stock_in_quantity" },
        },
        {
          title: "推荐仓库",
          dataIndex: "warehouse",
          key: "warehouse",
          width: 200,
          scopedSlots: { customRender: "warehouse" },
        },
        {
          title: "物料批次",
          dataIndex: "batch_number",
          key: "batch_number",
          width: 120,
          scopedSlots: { customRender: "batch_number" },
        },
        {
          title: "生产日期",
          dataIndex: "production_date",
          key: "production_date",
          width: 150,
          scopedSlots: { customRender: "production_date" },
        },
        {
          title: "保质期天数",
          dataIndex: "shelf_life_days",
          key: "shelf_life_days",
          width: 120,
        },
        {
          title: "单位",
          dataIndex: "unit_name",
          key: "unit_name",
          width: 80,
        },
      ],
    };
  },
  computed: {
    userId() {
      return this.$store.state.user.id;
    },
  },
  methods: {
    async confirm() {
      for (const item of this.dataItems) {
        if (item.stock_in_quantity > item.remain_quantity) {
          this.$message.error(`${item.goods_name} 入库数量不能大于待入库数量 ${item.remain_quantity}`);
          return;
        }
        if (item.stock_in_quantity <= 0) {
          this.$message.error(`${item.goods_name} 入库数量不能小于等于0`);
          return;
        }
        if (!item.warehouse) {
          this.$message.error(`${item.goods_name} 请选择仓库`);
          return;
        }
      }

      const groupedData = this.dataItems.reduce((acc, item) => {
        if (!acc[item.stock_in_order]) {
          acc[item.stock_in_order] = [];
        }
        acc[item.stock_in_order].push(item);
        return acc;
      }, {});

      for (const [key, value] of Object.entries(groupedData)) {
        await stockInCreate({
          stock_in_order: key,
          handler: this.userId,
          handle_time: moment().format("YYYY-MM-DD"),
          stock_in_record_goods_items: value.map((item) => {
            return {
              stock_in_goods: item.id,
              stock_in_quantity: item.stock_in_quantity,
              batch_number: item.batch_number,
              production_date: item.production_date,
              warehouse: item.warehouse,
            };
          }),
        });
      }

      this.$message.success("入库完成");
      this.confirmLoading = false;
      this.$emit("create");
      this.cancel();
    },
    cancel() {
      this.$emit("cancel", false);
    },
  },
  watch: {
    visible(value) {
      if (value) {
        warehousesOption({ page_size: 999999, is_active: true }).then((data) => {
          this.warehouseItems = data.results;
        });
        userOption({ page_size: 999999, is_active: true }).then((data) => {
          this.handlerItems = data.results;
        });

        this.queryLoading = true;
        const dataItems = [];
        for (const item of this.items) {
          const productItems = item.stock_in_goods_items.map((item) => ({ ...item, warehouse: undefined }));

          for (const productItem of productItems) {
            productItem.stock_in_order = productItem.id;
            productItem.total_quantity = productItem.stock_in_quantity;
            productItem.stock_in_quantity = productItem.remain_quantity;
            productItem.stock_in_order_number = item.number;
            productItem.goods_number = productItem.goods_number;
            productItem.goods_name = productItem.goods_name;

            if (productItem.editable_batch_number) {
              productItem.batch_number = "B" + moment().format("YYYYMMDD");
            } else {
              productItem.batch_number = "N/A";
            }

            // 推荐仓库
            const inventoryItems = productItem.inventory_items.filter((_item) => _item.total_quantity > 0);
            inventoryItems.sort((a, b) => b.total_quantity - a.total_quantity);
            if (inventoryItems.length > 0) {
              const inventoryItem = inventoryItems[0];
              inventoryItems.warehouse = inventoryItem["warehouse"];
            }

            dataItems.push(productItem);
          }
        }
        this.dataItems = dataItems;
        this.queryLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
